import axios from '@/utils/axios'
let {get, post } = axios
let API = {};

//首页banner
API.banner = function(data){return  post("/api/index/banner",data)}

//获取更新广告
API.notice = function(data){return  post("/api/index/notice",data)}

//获取行业资讯
API.message = function(data){return  post("/api/index/message",data)}

//获取榜单
API.ranking = function(data){return  post("/api/index/ranking",data)}

//公共搜索
API.search = function(data){return post("/api/index/search",data)}

//收藏博主-博文监控
API.collect = function(data){return post("/api/index/collect",data)}

export default API