const blogRouter = [{
    path: "/brandSearch",
    name: "BrandSearch",
    meta: {
      title: '品牌搜索 '
    },
    component: () => import("@/views/brand/brandSearch.vue")
  },
  {
    path: "/brandSearch/detail",
    name: "BrandDetail",
    meta: {
      title: '品牌分析',
      activeMenu:'/brandSearch',//设置选中路径，父页面的path
    },
    component: () => import("@/views/brand/detail.vue")
  },
  {
    path: "/brandGive",
    name: "BrandGive",
    meta: {
      title: '品牌分析示例',
      activeMenu:'/brandSearch',//设置选中路径，父页面的path
    },
    component: () => import("@/views/brand/brandGive.vue")
  },
  {
    path: "/brandRanking",
    name: "BrandRanking",
    meta: {
      title: '品牌排行',
    },
    component: () => import("@/views/brand/brandRanking/brandRanking.vue")
  },
  {
      path: "/brandIncRanking",
      name: "BrandIncRanking",
      meta: {
          title: '品牌排行-上升榜',
      },
      component: () => import("@/views/brand/brandRanking/brandIncRanking.vue")
  },
  {
      path: "/brandHotRanking",
      name: "BrandHotRanking",
      meta: {
          title: '品牌热搜榜',
      },
      component: () => import("@/views/brand/brandRanking/brandHotRanking.vue")
  },
  {
    path: "/brandContrast",
    name: "BrandContrast",
    meta: {
      title: '品牌对比',
    },
    component: () => import("@/views/brand/brandContrast/brandContrast.vue")
  },
  {
    path: "/brandContrastHis",
    name: "BrandContrastHis",
    meta: {
      title: '品牌对比历史',
      activeMenu:'/brandContrast',//设置选中路径，父页面的path
    },
    component: () => import("@/views/brand/brandContrast/brandContrastHis.vue")
  },
  {
    path: "/brandContrast/detail",
    name: "BrandContrast_detail",
    meta: {
      title: '品牌对比报告',
      activeMenu:'/brandContrast',//设置选中路径，父页面的path
    },
    component: () => import("@/views/brand/brandContrast/detail.vue")
  },
]
export default blogRouter
