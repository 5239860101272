const dataExport = [
  {
    path: "/dataExport",
    name: "DataExport",
    meta: {
      title: '数据导出'
    },
    component: () => import("@/views/dataExport/index.vue")
  },
  {
    path: "/dataExportRecord",
    name: "DataExportRecord",
    meta: {
      title: '数据导出列表'
    },
    component: () => import("@/views/dataExport/record.vue")
  }
]
export default dataExport
