const userRouter = [
  {
    path: "/user",
    name: "User",
    meta: {
      title: '账户信息'
    },
    component: () => import("@/views/user/user.vue")
  },
  {
    path: "/orderInfo",
    name: "OrderInfo",
    meta: {
      title: '订单中心'
    },
    component: () => import("@/views/user/orderInfo.vue")
  },
  {
    path: "/invoice",
    name: "Invoice",
    meta: {
      title: '开具发票',
      activeMenu:'/orderInfo',//设置选中路径，父页面的path
    },
    component: () => import("@/views/user/invoice.vue")
  },
  {
    path: "/inform",
    name: "Inform",
    meta: {
      title: '行业咨询-列表',
    },
    component: () => import("@/views/inform/inform.vue")
  },
  {
    path: "/inform/detail",
    name: "Inform_detail",
    meta: {
      title: '行业咨询-详情',
    },
    component: () => import("@/views/inform/detail.vue")
  },
  {
      path: "/shares",
      name: "Shares",
      meta: {
          title: '有偿拉新',
      },
      component: () => import("@/views/shares/index.vue")
  },
  {
      path: "/withdraw",
      name: "Withdraw",
      meta: {
          title: '提现记录',
      },
      component: () => import("@/views/user/withdraw.vue")
  }
]
export default userRouter
