const collectRouter = [{
  path: "/bosCollect",
  name: "BosCollect",
  meta: {
    title: '博主收藏'
  },
  component: () => import("@/views/myCollect/bosCollect.vue")
},{
  path: "/brandCollect",
  name: "BrandCollect",
  meta: {
    title: '品牌收藏',
    activeMenu:'/bosCollect',//设置选中路径，父页面的path
  },
  component: () => import("@/views/myCollect/brandCollect.vue")
}]
export default collectRouter
