const brandSearch = [
    {
        path: "/brandAudience",
        name: "BrandAudience",
        meta: {
            title: '品牌受众分析'
        },
        component: () => import("@/views/brandAudience/brandAudience.vue")
    },
    {
        path: "/brandAudienceLog",
        name: "BrandAudienceLog",
        meta: {
            title: '分析记录'
        },
        component: () => import("@/views/brandAudience/brandAudienceLog.vue")
    },
    {
        path: "/brandAudienceResult",
        name: "BrandAudienceResult",
        meta: {
            title: '受众分析详情'
        },
        component: () => import("@/views/brandAudience/brandAudienceResult.vue")
    },
]
export default brandSearch
