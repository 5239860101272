import axios from '@/utils/axios'
let {get, post } = axios
let API = {};
API.login = function(data){return  post("/api/user/login",data)}

//个人中心信息
API.personalInfo = function(data){return  post("/api/user/getPersonalInfo",data)}
//修改昵称
API.changeUserName = function(data){return  post("/api/user/changeUserName",data)}
//修改密码
API.changePwd = function(data){return  post("/api/user/changePwd",data)}
//发送短信验证码（换绑手机号时调用）
API.sendSmsBinding = function(data){return  post("/api/user/sendSmsBinding",data)}
//手机号换绑
API.bindingMobile = function(data){return  post("/api/user/bindingMobile",data)}
//获取验证码
API.setQrCode = function(data){return  post("/api/user/setQrCode",data)}
//检查换绑手机时是否为本人操作(轮询检查二维码)
API.changeBinding = function(data){return  post("/api/user/changeBinding",data)}


//订单列表
API.orderList = function(data){return  post("/api/user/order_list",data)}
// 提现订单列表
API.withdrawOrderList = function(data){return  post("/api/user/withdraw_order_list",data)}


//开具发票
API.invoice_ask = function(data){return  post("/api/invoice/invoice_ask",data)}
// 获取地区接口查询
API.condition = function(data){return  post("/api/common/condition",data)}
API.upload_file = function(data){return  post("/api/Invoice/upload_file",data)}

// 开票记录
API.invoice_lists = function(data){return  post("/api/Invoice/invoice_lists",data)}
// 开票记录详情
API.invoice_detail = function(data){return  post("/api/invoice/invoice_detail",data)}
// 开票提交
API.invoice_submit = function(data){return  post("/api/Invoice/invoice_submit",data)}
// 开票重新提交
API.invoice_reset = function(data){return  post("/api/Invoice/invoice_reset",data)}

// 优惠券列表
API.userCoupon = function(data){return  post("/api/coupon/userCoupon",data)}
// 手动领取优惠券
API.manualCoupon = function(data){return  post("/api/coupon/manualCoupon",data)}

// 图片上传
API.uploadImage = function(data){return  post("/api/common/uploadImage",data)}
// 提现提交
API.withdrawSub = function(data){return  post("/api/user/withdrawSub",data)}
// 礼品兑换
API.exchange = function(data){return  post("/api/user/exchange",data)}


//注销时核对密码
API.checkPwd = function(data){return  post("/api/user/checkPwd",data)}
//注销时获取验证码
API.getCancelCode = function(data){return  post("/api/user/getCancelCode",data)}
//注销时核对验证码
API.checkCode = function(data){return  post("/api/user/checkCode",data)}
//注销
API.cancelSubmit = function(data){return  post("/api/user/cancelSubmit",data)}

export default API
