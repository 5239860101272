import { ref,watch,reactive,onMounted } from 'vue';

import { getItem,setItem } from "@/utils/utils.js";
import { TOKEN } from '@/const/index.js';
import API from '@/api/header'
// 公共js
import common from '@/api/common.js'
import { baseUrl } from "@/config";
import exportApi from '@/api/mutual.js';

// 获取当前页面统计
export async function flowStatistics(type,content){
  let Equipment = {cip: window.sessionStorage.getItem('user_ip')}
  setTimeout(() => {
    // 获取ip等设备信息
    // let City = typeof(returnCitySN)
    // Equipment = ('object' == City)?returnCitySN:{cip:''}
    // 判断是否登录
    if(!getItem(TOKEN) || getItem(TOKEN) == undefined || getItem(TOKEN) == null) return
    API.flow_statistics({type,content,url:window.location.href,ip:Equipment.cip})
  }, 500)
}
// 功能使用统计
export function funStatistics(mod_name,content,fun_name,url){
  common.fun_statistics({mod_name,content,fun_name,url:`${baseUrl}${url}`})
}

export function exportData(url,data,name) {
  let date=new Date()
    var seperator1 = "-";
    var year = date.getFullYear();
    var month = date.getMonth() + 1;
    var strDate = date.getDate();
    if (month >= 1 && month <= 9) {
            month = "0" + month;
    }
    if (strDate >= 0 && strDate <= 9) {
        strDate = "0" + strDate;
    }
    var currentdate = year + seperator1 + month + seperator1 + strDate;
    exportApi.url(data).then(res=>{
      var downloadElement = document.createElement('a');
      var href = window.URL.createObjectURL(res); //创建下载的链接
      downloadElement.href = href;
      downloadElement.download = `${name}-${currentdate}.csv`; //下载后文件名
      document.body.appendChild(downloadElement);
      downloadElement.click(); //点击下载
      document.body.removeChild(downloadElement); //下载完成移除元素
      window.URL.revokeObjectURL(href); //释放掉blob对象
    })
}
export function formDate(datetime) {
  // 获取年月日时分秒值  slice(-2)过滤掉大于10日期前面的0
  let time = new Date(datetime * 1000);
  var year = time.getFullYear(),
      month = ("0" + (time.getMonth() + 1)).slice(-2),
      date = ("0" + time.getDate()).slice(-2),
      hour = ("0" + time.getHours()).slice(-2),
      minute = ("0" + time.getMinutes()).slice(-2),
      second = ("0" + time.getSeconds()).slice(-2);
  // 拼接
  var result = year + "-"+ month +"-"+ date +" "+ hour +":"+ minute +":" + second;
  // 返回
  return result;
}

export default {
  flowStatistics,
  funStatistics,
  exportData,
  formDate
}
