import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue";
export default {
  setup(__props) {
    //百度统计
    var _hmt = _hmt || [];

    (function () {
      var hm = document.createElement("script");
      hm.src = "https://hm.baidu.com/hm.js?d8d4a114a4a896ace6775fa589f87f83";
      var s = document.getElementsByTagName("script")[0];
      s.parentNode.insertBefore(hm, s); // const meta = document.createElement('meta');
      // meta.content='same-origin';
      // meta.content='no-referrer';
      // meta.name='referrer';
      // document.getElementsByTagName('head')[0].appendChild(meta);
    })(); // //外链图片显示
    // import '@/utils/referrer.js'


    return (_ctx, _cache) => {
      const _component_router_view = _resolveComponent("router-view");

      return _openBlock(), _createBlock(_component_router_view);
    };
  }

};