import axios from '@/utils/axios'
let {get, post } = axios
let API = {};

//用户需求反馈表单提交
API.need_form = function(data){return  post("/api/user/need_form",data)}
// 用户需求反馈弹窗统计
API.popup_statistics = function(data){return  post("/api/common/popup_statistics",data)}
// 页面浏览统计
API.flow_statistics = function(data){return  post("/api/common/flow_statistics",data)}
// 意见反馈上传图片
API.upload_image = function(data){return  post("/api/common/uploadImage",data)}
// 意见反馈提交
API.opinion_sub = function(data){return  post("/api/user/opinionSub",data)}
// 意见反馈记录
API.get_opinion_log = function(data){return  post("/api/user/opinionLog",data)}

export default API
