const tool = [
    {
        path: "/wordCloud",
        name: "WordCloud",
        meta: {
            title: '词云工具'
        },
        component: () => import("@/views/tool/wordCloud.vue")
    },
    {
        path: "/cloudLog",
        name: "CloudLog",
        meta: {
            title: '词云记录'
        },
        component: () => import("@/views/tool/cloudLog.vue")
    },
]
export default tool
