import { unref as _unref, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
const _hoisted_1 = {
  style: {
    "position": "absolute"
  }
};
const _hoisted_2 = {
  class: "group-box"
};
const _hoisted_3 = {
  class: "img-box"
};
const _hoisted_4 = ["src"];
import { ref, computed, onMounted, reactive, watch } from "vue";
import { toRaw } from '@vue/reactivity';
import { useStore } from "vuex";
import { getItem, setItem } from '@/utils/utils';
import { USER_AUTH, TOKEN } from '@/const/index.js';
import { ElMessage, ElLoading } from "element-plus";
import API from "@/api/user";
export default {
  setup(__props, {
    expose
  }) {
    const store = useStore();
    const showLogin = ref(); // 获取活动物料配置

    const material = toRaw(store.state);
    const groupDialog = ref(false); // const groupDialog_gy=ref(false)
    // 是否显示优惠券弹框  0：不显示  1：优惠券类型1   2：优惠券类型2

    const groupShow = ref(0);

    const groupClick = async () => {
      if (!getItem(TOKEN)) return showLogin.value.openLogin(true); // 弹窗交互，0：无交互，1：领取优惠券，2：跳转链接',

      if (material.material.pop_type == 0) {
        return;
      } else if (material.material.pop_type == 2) {
        window.open(material.material.pop_url, '_blank');
        return;
      }

      const loading = ElLoading.service({
        spinner: '',
        background: 'rgba(255, 255, 255, 0.5)'
      });
      const res = await API.manualCoupon();
      loading.close();

      if (res.code == 1) {
        ElMessage.success({
          message: '优惠券已放入您的账号，可于账户信息中查看！',
          duration: '3000'
        });
        groupDialog.value = false;
        setTimeout(() => {
          window.location.reload();
        }, 2000);
      } else {
        if (res.data.code == 1) {
          ElMessage.warning({
            message: '已领取过优惠券，无法重复领取!',
            duration: '3000'
          });
        } else {
          ElMessage.warning({
            message: '没有需要领取的优惠券!',
            duration: '3000'
          });
        }
      }
    }; // 第二步：暴露方法


    expose({
      groupDialog,

      /*  groupDialog_gy, */
      groupShow
    });
    return (_ctx, _cache) => {
      const _component_el_dialog = _resolveComponent("el-dialog");

      const _component_vLogin = _resolveComponent("vLogin");

      return _openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(_component_el_dialog, {
        "custom-class": "groupModel",
        modelValue: groupDialog.value,
        "onUpdate:modelValue": _cache[1] || (_cache[1] = $event => groupDialog.value = $event),
        "append-to-body": "",
        "close-on-click-modal": false,
        width: "30%",
        top: "15vh"
      }, {
        default: _withCtx(() => [_createElementVNode("div", _hoisted_2, [_createElementVNode("div", _hoisted_3, [_createElementVNode("img", {
          src: _unref(material).material.pop_pic,
          onClick: groupClick,
          alt: "",
          style: {
            "width": "700px",
            "height": "450px"
          }
        }, null, 8, _hoisted_4)]), _createElementVNode("i", {
          class: "iconfont icon-guanbi1",
          onClick: _cache[0] || (_cache[0] = $event => groupDialog.value = false)
        })])]),
        _: 1
      }, 8, ["modelValue"]), _createVNode(_component_vLogin, {
        ref_key: "showLogin",
        ref: showLogin
      }, null, 512)]);
    };
  }

};