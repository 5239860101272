const bosFindRouter = [{
    path: "/bosSearch",
    name: "BosSearch",
    meta: {
      title: '博主搜索'
    },
    component: () => import("@/views/bosFind/bosSearch/bosSearch.vue")
  },
  {
      path: "/bosSearch/detail",
      name: "BosSearch_detail",
      meta: {
        title: '博主详情',
        activeMenu:'/bosSearch',//设置选中路径，父页面的path
      },
      component: () => import("@/views/bosFind/bosSearch/detail.vue")
  },
  {
    path: "/bosContrast",
    name: "BosContrast",
    meta: {
      title: '博主对比'
    },
    component: () => import("@/views/bosFind/bosContrast/bosContrast.vue")
  },
  {
    path: "/bosContrastHis",
    name: "BosContrastHis",
    meta: {
      title: '博主对比历史',
      activeMenu:'/bosContrast',//设置选中路径，父页面的path
    },
    component: () => import("@/views/bosFind/bosContrast/bosContrastHis.vue")
  },
  {
      path: "/bosContrast/detail",
      name: "BosContrast_detail",
      meta: {
        title: '博主对比报告',
        activeMenu:'/bosContrast',//设置选中路径，父页面的path
      },
      component: () => import("@/views/bosFind/bosContrast/detail.vue")
  },
  {
    path: "/synthe",
    name: "Synthe",
    meta: {
      title: '博主排行'
    },
    component: () => import("@/views/bosFind/ranking/synthe.vue")
  },
  {
    path: "/area",
    name: "Area",
    meta: {
      title: '博主排行',
      activeMenu:'/synthe',//设置选中路径，父页面的path
    },
    component: () => import("@/views/bosFind/ranking/area.vue")
  },
  {
    path: "/getfans",
    name: "Getfans",
    meta: {
      title: '博主排行',
      activeMenu:'/synthe',//设置选中路径，父页面的path
    },
    component: () => import("@/views/bosFind/ranking/getfans.vue")
  },
    {
        path: "/monitor",
        name: "Monitor",
        meta: {
            title: '博主监控',
            // activeMenu:'/synthe',//设置选中路径，父页面的path
        },
        component: () => import("@/views/bosFind/monitor/index.vue")
    },
    {
        path: "/monitorHistory",
        name: "MonitorHistory",
        meta: {
            title: '监控历史',
            activeMenu:'/monitor',//设置选中路径，父页面的path
        },
        component: () => import("@/views/bosFind/monitor/history.vue")
    },

    {
        path: "/stope",
        name: "Stope",
        meta: {
            title: '博主回采'
        },
        component: () => import("@/views/bosFind/stope/index.vue")
    },
    {
        path: "/stopeHistory",
        name: "StopeHistory",
        meta: {
            title: '回采记录'
        },
        component: () => import("@/views/bosFind/stope/history.vue")
    },
    {
        path: "/stopeResult",
        name: "StopeResult",
        meta: {
            title: '回采报告'
        },
        component: () => import("@/views/bosFind/stope/result.vue")
    },

]
export default bosFindRouter
