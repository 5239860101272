import axios from '@/utils/axios'
import exportApi from "@/api/mutual";
let {get, post} = axios
let API = {};

//地址检测
API.checkUrl = function(data){return post("/api/art_mon/checkUrl",data)}

//开始监测
API.formSubmit = function(data){return post("/api/art_mon/submit",data)}

//查找博主
API.searchAccount = function(data){return post("/api/art_mon/searchAccount",data)}

//检测用户本次消耗次数和本月剩余次数
API.remainingTimes = function(data){return post("/api/art_mon/remainingTimes",data)}

//监测历史
API.getHistory = function(data){return post("/api/art_mon/history",data)}

//删除监测任务
API.delHistory = function(data){return post("/api/art_mon/deleteTask",data)}

//监测报告(左侧数据)
API.getLeftSide = function(data){return post("/api/art_mon/reportContent",data)}

//监测报告-核心数据-数据对比
API.getCoreCom = function(data){return post("/api/art_mon/getCoreContrast",data)}

//监测报告-核心数据-整体趋势
API.getCoreTrend = function(data){return post("/api/art_mon/getCoreTrend",data)}

//监测报告-核心数据-频次详情
API.getCoreFrequency = function(data){return post("/api/art_mon/getCoreFrequencyList",data)}

//监测报告-参与者分析
API.getParticipant = function(data){return post("/api/art_mon/participant",data)}

// 监测报告-传播者分析
API.getDiffuse = function(data){return post("/api/art_mon/diffuse",data)}




//权限检测
API.getUserAuth = function(data){return post("/api/art_mon/available",data)}

//博文搜索
API.getblogSearch = function(data){return post("/api/article/search",data)}
//博文搜索
API.blogSearchAuth = function(data){return post("/api/article/get_search_auth",data)}


// 博主监控
API.getMonitorAccount = function(data){return post("/api/kol/account_search",data)}
API.accountMonitorSubmit = function(data){return post("/api/kol/account_monitor_submit",data)} // 表单提交
API.getAccountMonitorAuth = function(data){return post("/api/kol/account_monitor_auth",data)} // 获取权限次数
API.getAccountMonitorHistory = function(data){return post("/api/kol/account_monitor_history",data)} // 获取监控记录
API.deleteMonitorHistory = function(data){return post("/api/kol/delete_monitor_history",data)} // 删除监控记录
API.editMonitorStatus = function(data){return post("/api/kol/edit_monitor_status",data)} // 修改监控状态
API.accountMonitorEdit = function(data){return post("/api/kol/account_monitor_edit",data)} // 修改监控范围
API.getAccountMonitorNoticeLog = function(data){return post("/api/kol/account_monitor_log",data)} // 获取通知记录
//博文观测-博文搜索-博文导出
API.bosSearchBlogExport = function(data){return post("/api/article/exportBlog",data,true)}
//博文观测-博文搜索-博文导出次数和消耗次数计算
API.getBlogExportTimes = function(data){return post("/api/article/getTimes",data)}
//博文观测-博文搜索-博文导出记录
API.getBlogExportRecode = function(data){return post("/api/article/getBlogExportRecode",data)}

export default API
