// 发布版本时，请配置好这个环境变量
// const env = 'development'
// const env = 'test'
// const env = 'release'
const env = 'production'

if (env == 'development') { // 开发
  baseUrl = 'http://localhost:8080'
  apiUrl = 'http://wbapi.com/api'

}else if(env == 'test'){
  baseUrl = 'http://192.168.1.60:8087'
  apiUrl = 'http://192.168.1.60:8086/api'

}else if(env == 'release'){
  baseUrl = 'http://192.168.1.60:8086'
  apiUrl = 'http://192.168.1.60:8086/api'

}else if(env == 'production'){
  baseUrl = 'https://testwb.topsocial.com.cn'
  apiUrl = 'https://testwbapi.topsocial.com.cn/api'
}

module.exports = {
    // env,
    baseUrl,
    apiUrl
}
