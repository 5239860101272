const mRouter = [
     // 登录页
     {
        path: "/m/login",
        component: () => import("@/m/login/index.vue")
    },
    // 首页
    {
        path: "/m/index",
        component: () => import("@/m/index.vue")
    },
    // 会员页面
    {
        path: "/m/member",
        component: () => import("@/m/member/index.vue")
    },
    {
        path: "/m/blogger",
        component: () => import("@/m/blogger/list.vue")
    },
    {
        path: "/m/ranking/list",
        component: () => import("@/m/ranking/list.vue")
    },
    //个人中心
    {
        path: "/m/user",
        component: () => import("@/m/user/user.vue")
    },
    //优惠券列表
    {
        path: "/m/coupon",
        component: () => import("@/m/user/coupon.vue")
    },
    //404页面
    {
        path: "/:catchAll(.*)",
        component: () => import("@/m/errorpage.vue")
    }
]
export default mRouter
