import {
  createRouter,
  createWebHashHistory,
  createWebHistory
} from 'vue-router'
import {TOKEN,LOGIN_ROUTER} from '@/const/index.js';
import {getItem,isMobile} from '@/utils/utils'

import Home from "../views/Home.vue";

import bosFindRouter from './bosFind.js';
import blogRouter from "./blog.js";
import topicRouter from "./topic.js";
import userRouter from "./user.js";
import memberRouter from "./member.js";
import brandRouter from "./brand.js";
import myCollectRouter from "./myCollect.js";
import lifeRouter from "./life.js";
import lifeCustomRouter from "./lifeCustom.js";
import tool from "./tool.js";
import hotSearch from "./hotSearch.js";
import brandReport from "./brandReport.js";
import brandAudience from "./brandAudience.js";
import dataExport from "./dataExport.js";
//移动端路由
import mRouter from "./m/m.js";

//PC端路由
const routerPC = [{
    path: '/',
    redirect: '/home'
  },
  {
    path: "/",
    name: "Home",
    component: Home,
    children: [
      {
        path: "/home",
        name: "Home",
        meta: {
          title: '首页'
        },
        component: () => import("@/views/home/index.vue")
      },
      {
        path: "/statistics",
        name: "Statistics",
        meta: {
          title: '数据大盘',
        },
        component: () => import("@/views/statistics/index.vue")
      },
      ...bosFindRouter, //博主监测
      ...blogRouter,    //博文监测
      ...topicRouter,   //舆情观测
      ...userRouter,    //个人中心
      ...myCollectRouter, //我的收藏
      ...memberRouter,  //充值续费
      ...brandRouter,   //品牌搜索
      ...lifeRouter,   //本地生活
      ...lifeCustomRouter,   //本地生活-数据定制
      ...tool,   //工具箱
      ...hotSearch,   //热搜
      ...brandReport,   //品牌报告
      ...brandAudience,   //品牌受众分析
      ...dataExport, //数据导出
    ]
  },
  {
    path:'/useHelp',
    name: "UseHelp",
    meta: {
      title: '使用帮助',
    },
    component: () => import("@/views/useHelp/index.vue")
  },
  {
    path:'/contact',
    name: "Contact",
    meta: {
      title: '联系我们',
    },
    component: () => import("@/views/useHelp/contact.vue")
  },
  {
    path:'/about',
    name: "About",
    meta: {
      title: '关于我们',
    },
    component: () => import("@/views/useHelp/about.vue")
  },
  {
      path:'/marketing',
      name: "Marketing",
      meta: {
          title: '营销首页',
      },
      component: () => import("@/views/market/marketing.vue")
  },
  //移动端
  // {
  //   path: "/m",
  //   component: () => import("@/m/m.vue"),
  //   children: [
  //     ...mRouter
  //   ]
  // }
];

//移动端路由
const routerM = [
  {
    path: '/',
    redirect: '/m/index'
  },
  {
      path: '/home',
      redirect: '/m/index'
  },
  {
    path: "/m/index",
    component: () => import("@/m/m.vue"),
    children: [
      ...mRouter
    ]
  }
]

const router = createRouter({
  // history: createWebHashHistory(), //hash为页面重置跳转首页
  history: createWebHistory(),        //history为页面不重置跳转首页
  routes:isMobile()?routerM:routerPC
})
router.beforeEach((to, from,nuxt)=>{
  //兼容路由中包含"#/"，直接跳转到首页
  if(location.origin.includes('topsocial.com.cn') && to.href.includes("#/")){
    nuxt(from.path)
  }

  //需要登录的路由
  if(getItem(TOKEN)){
    nuxt()
  }else{
    //跳转首页
    if(LOGIN_ROUTER.includes(to.path)){
      nuxt(from.path)
    }else{
      nuxt()
    }
    //博文监控
    // const loginRouter = ['/blogReport','/blogDetail']
    // if(loginRouter.includes(to.path)){
    //   nuxt('/blogMonitor')
    // }
  }
});
router.onError((error) => {
  const pattern = /Loading chunk (\d)+ failed/g;
  const isChunkLoadFailed = error.message.match(pattern);
  const targetPath = router.history.pending.fullPath;
  if (isChunkLoadFailed) {
    router.replace(targetPath);
  }
});
export default router
