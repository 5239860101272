const topicRouter = [{
    path: "/topicMonitor",
    name: "TopicMonitor",
    meta: {
      title: '话题监测'
    },
    component: () => import("@/views/topic/topicMonitor.vue")
  },
  {
    path: "/topicReport",
    name: "TopicReport",
    meta: {
      title: '话题监测报告'
    },
    component: () => import("@/views/topic/topicReport.vue")
  },
  {
    path: "/topicDetail",
    name: "TopicDetail",
    meta: {
      title: '话题监测报告详情',
      activeMenu:'/topicMonitor',//设置选中路径，父页面的path
    },
    component: () => import("@/views/topic/detail.vue")
  },
  {
    path: "/topictopRanking",
    name: "TopictopRanking",
    meta: {
      title: '话题排行',
    },
    component: () => import("@/views/topic/topictopRanking.vue")
  },
  {
      path: "/topicRecreationRanking",
      name: "TopicRecreationRanking",
      meta: {
          title: '话题排行',
          activeMenu:'/topictopRanking',//设置选中路径，父页面的path
      },
      component: () => import("@/views/topic/topicRecreationRanking.vue")
  },
  {
    path: "/topicheatedRanking",
    name: "TopicheatedRanking",
    meta: {
      title: '话题排行',
      activeMenu:'/topicheatedRanking',//设置选中路径，父页面的path
    },
    component: () => import("@/views/topic/topicheatedRanking.vue")
  },
    {
        path: "/topicAnalysis",
        name: "TopicAnalysis",
        meta: {
            title: '话题分析',
            activeMenu:'/topicAnalysis',//设置选中路径，父页面的path
        },
        component: () => import("@/views/topic/topicAnalysis.vue")
    },
    {
        path: "/topicAnalysisLog",
        name: "TopicAnalysisLog",
        meta: {
            title: '分析记录',
            activeMenu:'/topicAnalysis',//设置选中路径，父页面的path
        },
        component: () => import("@/views/topic/topicAnalysisLog.vue")
    },
    {
        path: "/topicAnalysisResult",
        name: "TopicAnalysisResult",
        meta: {
            title: '分析报告',
            activeMenu:'/topicAnalysis',//设置选中路径，父页面的path
        },
        component: () => import("@/views/topic/topicAnalysisResult.vue")
    },
]
export default topicRouter
