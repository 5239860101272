
import axios from '@/utils/axios'
import API from "@/api/blog";
let {get, post} = axios
let exportApi = {};
// ----------------- 整体导出模块 ---------------------
// 博主PK导出
exportApi.getExportResult = function(data){return post("/api/comp/exportResult",data,true)}
//博文详情导出
exportApi.getExportCoreFrequencyList = function(data){return post("/api/art_mon/exportCoreFrequencyList",data,true)}
//话题榜单-话题详情-导出
exportApi.getExportTrendList = function(data){return post("/api/topic/exportTrendList",data,true)}

//博主榜单导出
exportApi.synthesize_export = function(data){return post("/api/kol_ranking/synthesize_export",data,true)}
exportApi.area_export = function(data){return post("/api/kol_ranking/area_export",data,true)}
exportApi.fans_inc_export = function(data){return post("/api/kol_ranking/fans_inc_export",data,true)}

//博文榜单导出
exportApi.exportArticle = function(data){return post("/api/article/exportArticle",data,true)}

//话题榜单-热搜榜-导出
exportApi.topSearchExport = function(data){return post("/api/topic_ranking/topSearchExport",data,true)}
//话题榜单-热议榜-导出
exportApi.topDisExport = function(data){return post("/api/topic_ranking/topDisExport",data,true)}
//话题榜单-文娱榜-导出
exportApi.recreationExport = function(data){return post("/api/topic_ranking/recreationExport",data,true)}

//品牌声量榜单-导出
exportApi.rankingExport = function(data){return post("/api/brand_ranking/rankingExport",data,true)}
//品牌上升榜单-导出
exportApi.incRankingExport = function(data){return post("/api/brand_ranking/incRankingExport",data,true)}
//品牌热搜榜单-导出
exportApi.hotRankingExport = function(data){return post("/api/brand_ranking/hotRankingExport",data,true)}

//博主详情-博文分析-作品导出
exportApi.bosSearchArticleExport = function(data){return post("/api/kol/analyseArticleExport",data,true)}
exportApi.bosSearchArticleExportCheck = function(data){return post("/api/kol/analyseArticleExportCheckNum",data)}

//博主详情-历史广告-作品导出
exportApi.adArtExport = function(data){return post("/api/kol/adArtExport",data,true)}
exportApi.adArtExportCheck = function(data){return post("/api/kol/adArtExportCheck",data,true)}
// 结案报告-导出博主
exportApi.finalResultExportBlogger = function(data){return post("/api/launch/exportBlogger",data,true)}
// 结案报告-博文导出
exportApi.finalResultExportArt = function(data){return post("/api/launch/exportArt",data,true)}

// 品牌结案报告-博文导出
exportApi.brandResultExportArt = function(data){return post("/api/brand_report/exportArt",data,true)}
// 品牌结案报告-导出博主
exportApi.brandResultExportBlogger = function(data){return post("/api/brand_report/exportBlogger",data,true)}

//博主查找-博主回采-回采报告-博文列表导出
exportApi.bosStopeArticleExport = function(data){return post("/api/crawling/analyseArticleExport",data,true)}
exportApi.bosStopeArticleExportCheck = function(data){return post("/api/crawling/analyseArticleExportCheckNum",data)}

export default exportApi
