import axios from '@/utils/axios'
let {get, post } = axios
let API = {};

API.login = function(data){return  post("/api/user/login",data)}

// 获取个人用户数据
API.getPersonalInfo = function(data){return  post("/api/user/getPersonalInfo",data)}
// 获取物料配置
API.getMaterial = function(data){return  post("/api/user/getMaterial",data)}
// 获取登录验证码
API.loginCaptchaSrc = function (data) {return post('/api/User/get_captcha',data)}
// 获取协议
API.getserverAgreement = function (data) {return post('/api/User/get_server_agreement',data)}
// 获取注册验证码
// API.getverificationCode = function (data) {return post('/api/User/checkMobileUnique', data)}
// 注册账号
// API.registerAccount = function (data) {return post('/api/User/register', data)}
// 验证手机号是否注册
API.getcheckMobile = function (data) {return post('/api/User/checkMobile', data)}
// 获取修改账号验证码
API.resetGetCode = function (data) {return post('/api/User/resetGetCode', data)}
// 修改账号密码
API.getrevampPassword = function (data) {return post('/api/User/restPwd', data)}
// 完成个人资料
// API.getInformationAdAjax = function (data) {return post('/api/user/InformationAdAjax', data)}
// 获取登录二维码
API.getloginOrCode = function (data) {return post('/api/User/getwxqrcode', data)}
// 检测是否登录
API.getWhetherLogin = function (data) {return post('/api/User/getwxlogin', data)}
// 登录并绑定
API.getloginBinding = function (data) {return post('/api/User/loginBinding', data)}
// 注册并绑定
API.getregisterBinding = function (data) {return post('/api/User/registerBinding', data)}
// 检查扫码绑定登录
API.getcheckLogin = function (data) {return post('/api/User/checkLogin', data)}
// 检查扫码绑定注册
API.getcheckRegister = function (data) {return post('/api/User/checkRegister', data)}
// 获取短信登录验证码
API.getMsgCode = function (data) {return post('/api/User/getMsgCode', data)}
// 执行短信登录
API.msgCodeLogin = function (data) {return post('/api/User/msgCodeLogin', data)}
// 获取绑定验证码
API.getBindingGetCode = function (data) {return post('/api/User/bindingGetCode', data)}
// 获取系统/用户通知消息
// API.getMessage = function (data) {return post('/api/message/get_message', data)}
// 阅读用户消息
// API.readuserMessage = function (data) {return post('/api/message/read_user_message', data)}

// 获取快捷方式
API.getQuickEntry = function (data) {return post('/api/User/getQuickEntry', data)}

export default API
