const blogRouter = [{
    path: "/blogMonitor",
    name: "BlogMonitor",
    meta: {
      title: '博文监测'
    },
    component: () => import("@/views/blog/blogMonitor.vue")
  },
  {
    path: "/blogReport",
    name: "BlogReport",
    meta: {
      title: '博文监测报告'
    },
    component: () => import("@/views/blog/blogReport.vue")
  },
  {
    path: "/blogDetail",
    name: "BlogDetail",
    meta: {
      title: '博文监测报告详情',
      activeMenu:'/blogMonitor',//设置选中路径，父页面的path
    },
    component: () => import("@/views/blog/detail.vue")
  },
  {
    path: "/blogHotRanking",
    name: "BlogHotRanking",
    meta: {
      title: '博文排行',
    },
    component: () => import("@/views/blog/blogHotRanking.vue")
  },
  {
    path: "/blogCakeRanking",
    name: "BlogCakeRanking",
    meta: {
      title: '博文排行',
      activeMenu:'/blogHotRanking',//设置选中路径，父页面的path
    },
    component: () => import("@/views/blog/blogCakeRanking.vue")
  },
  {
    path: "/blogSearch",
    name: "BlogSearch",
    meta: {
      title: '博文搜索',
    },
    component: () => import("@/views/blog/blogSearch.vue")
  },
    {
        path: "/finalReport",
        name: "FinalReport",
        meta: {
            title: '结案报告',
        },
        component: () => import("@/views/blog/finalReport.vue")
    },
    {
        path: "/finalReportLog",
        name: "FinalReportLog",
        meta: {
            title: '报告记录',
        },
        component: () => import("@/views/blog/finalReportLog.vue")
    },
    {
        path: "/finalResult",
        name: "FinalResult",
        meta: {
            title: '报告详情',
        },
        component: () => import("@/views/blog/finalResult.vue")
    },
    {
        path: "/propagationAnalysis",
        name: "PropagationAnalysis",
        meta: {
            title: '传播分析',
            activeMenu:'/propagationAnalysis',//设置选中路径，父页面的path
        },
        component: () => import("@/views/blog/propagationAnalysis.vue")
    },
    {
        path: "/propagationAnalysisLog",
        name: "PropagationAnalysisLog",
        meta: {
            title: '分析记录',
            activeMenu:'/propagationAnalysis',//设置选中路径，父页面的path
        },
        component: () => import("@/views/blog/propagationAnalysisLog.vue")
    },
    {
        path: "/propagationAnalysisResult",
        name: "PropagationAnalysisResult",
        meta: {
            title: '分析报告',
            activeMenu:'/propagationAnalysis',//设置选中路径，父页面的path
        },
        component: () => import("@/views/blog/propagationAnalysisResult.vue")
    },
]
export default blogRouter
