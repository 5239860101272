const brandSearch = [
    {
        path: "/brandReport",
        name: "BrandReport",
        meta: {
            title: '品牌报告'
        },
        component: () => import("@/views/brandReport/brandReport.vue")
    },
    {
        path: "/brandReportLog",
        name: "brandReportLog",
        meta: {
            title: '报告记录'
        },
        component: () => import("@/views/brandReport/brandReportLog.vue")
    },
    {
        path: "/brandResult",
        name: "BrandResult",
        meta: {
            title: '报告详情'
        },
        component: () => import("@/views/brandReport/brandResult.vue")
    },
]
export default brandSearch
