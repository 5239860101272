import {
  createApp
} from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import {isMobile} from '@/utils/utils'
import installElementPlus from './plugins/element'
// Vant 组件库的引入
// import  installVant from './plugins/m/Vant';
//登录弹框
import vLogin from "@/components/common/Login.vue";
//全局权限弹框
import permModel from "@/components/dialog/permModel.vue";

//判断是移动端还是pc端
if(!isMobile()){
  require('@/assets/iconfont/iconfont.css?t=20221114') //图标库
  require('@/assets/iconfont/iconfont.js?t=20221114') //图标库js
  require('@/assets/css/common.less') //公共样式
  require('@/assets/css/fontFamily/YouSheBiaoTiHei.css') //字体风格样式
}else{
  // flexIble.js 适配
  require('@/utils/m/flexIble.js') //公共样式
}

// 暂时注释不要删除 2022-12-7
// import '@/assets/iconfont/iconfont.css?t=20221114' //图标库
// import '@/assets/iconfont/iconfont.js?t=20221114' //图标库js
// import '@/assets/css/common.less' //公共样式
// import '@/assets/css/fontFamily/YouSheBiaoTiHei.css' //字体风格样式

const app = createApp(App)
app.component('vLogin',vLogin)
app.component('permModel',permModel)

// Vant 组件库的引入
// installVant(app)
installElementPlus(app)
app.use(store).use(router).mount('#app')
