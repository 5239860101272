const hotSearch = [
    {
        path: "/hotSearch",
        name: "HotSearch",
        meta: {
            title: '热搜报告'
        },
        component: () => import("@/views/hotSearch/hotSearch.vue")
    },
    {
        path: "/hotLog",
        name: "HotLog",
        meta: {
            title: '报告记录'
        },
        component: () => import("@/views/hotSearch/hotLog.vue")
    },
    {
        path: "/hotDetail",
        name: "HotDetail",
        meta: {
            title: '报告详情'
        },
        component: () => import("@/views/hotSearch/hotDetail.vue")
    },
    {
        path: "/hotHistory",
        name: "HotHistory",
        meta: {
            title: '历史热搜搜索'
        },
        component: () => import("@/views/hotSearch/hotHistory.vue")
    },
]
export default hotSearch
